import React from 'react';

function App() {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.title}>The Everything Festival: Las Vegas</h1>
        <p style={styles.description}>More information coming soon.</p>
      </div>
      <footer style={styles.footer}>
        made with love by 1hash2deep
      </footer>
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: '#000',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
  },
  content: {
    textAlign: 'center',
    marginBottom: 'auto',
  },
  title: {
    fontSize: '2.5em',
    margin: '0',
  },
  description: {
    fontSize: '1.2em',
    marginTop: '10px',
  },
  footer: {
    marginTop: 'auto',
    padding: '20px 0',
    fontSize: '1em',
    textAlign: 'center',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    borderBottom: '1px solid #fff',
  },
};

export default App;
